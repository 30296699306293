import { APP } from '../..'
import $ from 'jquery'
import FormControl from './FormControl'

export default class Range extends FormControl {
  constructor (data, type) {
    super(type, data)

    Promise.all(this.promises).then(() => {
      this.setValue()
      this.setTriggers()
      this.trigger()
    })
  }

  setValue = () => {
    $(this.document)
      .find('input[type=range]')
      .each((_, element) => {
        if ($(element).is('[data-minmax]')) {
          // Conform values
          const value = []
          this.filter.value.forEach((v) => {
            if (v === Infinity) {
              v = this.filter.data.max
            }
            if (v === -Infinity) {
              v = this.filter.data.min
            }
            value.push(v)
          })

          if ($(element).attr('data-minmax') === 'min') {
            $(element).val(value[0])
          }

          if ($(element).attr('data-minmax') === 'max') {
            $(element).val(this.filter.data.max - value[1])
          }
        } else {
          // Conform value
          let value = this.filter.value
          if (value === Infinity) {
            value = this.filter.data.max
          }
          if (value === -Infinity) {
            value = this.filter.data.min
          }
          $(element).val(value)
        }
      })

    this.trigger()
  }

  getValue = () => {
    let value = []
    $(this.document)
      .find('input[type=range]')
      .each((_, element) => {
        if ($(element).is('[data-minmax]')) {
          if ($(element).is('[data-minmax=min]')) {
            value[0] = Number($(element).attr('data-value'))
          }
          if ($(element).is('[data-minmax=max]')) {
            value[1] = Number($(element).attr('data-value'))
          }
          // Change to +/- Infinity if requried
          for (const i in value) {
            if (value[i] <= this.filter.min) {
              value[i] = -Infinity
            }
            if (value[i] >= this.filter.max) {
              value[i] = Infinity
            }
          }
        } else {
          value = Number($(element).attr('data-value'))
          // Change to +/- Infinity if requried
          if (value <= this.filter.min) {
            value = -Infinity
          }
          if (value >= this.filter.max) {
            value = Infinity
          }
        }
      })

    return value
  }

  setTriggers = () => {
    $(this.document)
      .find('input[type=range]')
      .off('change')
      .on('change', (event) => {
        const thumbWidth = parseInt(
          $(this.document).css('--filters-button-size')
        )

        const target = event.currentTarget

        // Single slider
        if (!$(target).is('[data-minmax]')) {
          $(target).attr({ 'data-value': $(target).val() })
          this.updateFilterValue()
          return
        }

        const parent = $(target).parent()
        const max = $(target).parent().data('max')
        const minValue = Number(
          $(parent)
            .children('input[type=range][data-minmax=min]')
            .val()
        )
        const maxValue = Number(
          $(parent)
            .children('input[type=range][data-minmax=max]')
            .val()
        )

        const minValueDecimal = minValue / max
        const maxValueDecimal = maxValue / max
        const minValuePercent = minValueDecimal * 100
        const maxValuePercent = maxValueDecimal * 100

        // Firefox specific
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
          $(parent)
            .children('input[type=range][data-minmax=min]')
            .css({
              right:
                                'calc(' +
                                maxValuePercent +
                                '% - ' +
                                thumbWidth * maxValueDecimal +
                                'px)',
              width:
                                'calc(100% - (' +
                                maxValuePercent +
                                '% - ' +
                                thumbWidth * maxValueDecimal +
                                'px))'
            })
            .attr('max', max - maxValue)
            .attr('data-value', minValue)

          $(parent)
            .children('input[type=range][data-minmax=max]')
            .css({
              left:
                                'calc(' +
                                minValuePercent +
                                '% - ' +
                                thumbWidth * minValueDecimal +
                                'px)',
              width:
                                'calc(100% - (' +
                                minValuePercent +
                                '% - ' +
                                thumbWidth * minValueDecimal +
                                'px))'
            })
            .attr('max', max - minValue)
            .attr('data-value', max - maxValue)

          return
        }

        $(parent)
          .children('input[type=range][data-minmax=min]')
          .css({
            right:
                            'calc(' +
                            maxValuePercent +
                            '% - ' +
                            thumbWidth * maxValueDecimal +
                            'px)',
            width:
                            'calc(100% - (' +
                            maxValuePercent +
                            '% - ' +
                            thumbWidth * maxValueDecimal +
                            'px))'
          })
          .attr('max', max - maxValue)
          .attr('data-value', minValue)

        $(parent)
          .children('input[type=range][data-minmax=max]')
          .css({
            left:
                            'calc(' +
                            minValuePercent +
                            '% - ' +
                            thumbWidth * minValueDecimal +
                            'px)',
            width:
                            'calc(100% - (' +
                            minValuePercent +
                            '% - ' +
                            thumbWidth * minValueDecimal +
                            'px))'
          })
          .attr('max', max - minValue)
          .attr('data-value', max - maxValue)

        this.updateFilterValue()
      })
      .off('input')
      .on('input', (event) => {
        const thumbWidth = parseInt(
          $(this.document).css('--filters-button-size')
        )
        const target = event.currentTarget
        const parent = $(target).parent()
        const max = $(target).parent().data('max')
        const name = $(parent).data('name')

        if (!$(target).is('[data-minmax]')) {
          const value = Number($(target).val())
          const valueDecimal = value / max
          const valuePercent = valueDecimal * 100
          let html = value

          $(parent)
            .children('input[type=range]+label')
            .removeClass('infinity')
          if (
            APP.attributes[name]?.type &&
                        APP.attributes[name].type === 'cotation'
          ) {
            html = APP.attributes[name].data[value]
          } else if (value === max) {
            html = ''
            $(parent)
              .children('input[type=range]+label')
              .addClass('infinity')
          }

          $(parent)
            .children('input[type=range]+label')
            .css({
              left:
                                'calc(' +
                                valuePercent +
                                '% - ' +
                                thumbWidth * valueDecimal +
                                'px)'
            })
            .html(html)

          return
        }

        const minValue = Number(
          $(parent)
            .children('input[type=range][data-minmax=min]')
            .val()
        )
        const maxValue = Number(
          $(parent)
            .children('input[type=range][data-minmax=max]')
            .val()
        )
        const minValueDecimal = minValue / max
        const maxValueDecimal = maxValue / max
        const minValuePercent = minValueDecimal * 100
        const maxValuePercent = maxValueDecimal * 100
        let minHtml = minValue
        let maxHtml = max - maxValue

        $(parent)
          .children('input[type=range][data-minmax=max]+label')
          .removeClass('infinity')
        if (
          APP.attributes[name]?.type &&
                    APP.attributes[name].type === 'cotation'
        ) {
          minHtml = APP.attributes[name].data[minValue]
          maxHtml = APP.attributes[name].data[max - maxValue]
        } else if (maxValue === 0) {
          maxHtml = ''
          $(parent)
            .children('input[type=range][data-minmax=max]+label')
            .addClass('infinity')
        }

        $(parent)
          .children('input[type=range][data-minmax=min]+label')
          .css({
            left:
                            'calc(' +
                            minValuePercent +
                            '% - ' +
                            thumbWidth * minValueDecimal +
                            'px)'
          })
          .html(minHtml)
        $(parent)
          .children('input[type=range][data-minmax=max]+label')
          .css({
            right:
                            'calc(' +
                            maxValuePercent +
                            '% - ' +
                            thumbWidth * maxValueDecimal +
                            'px)'
          })
          .html(maxHtml)

        $(target).next().css({ 'z-index': '9' })
        $(target).css({ 'z-index': '8' })
        $(target)
          .siblings('input[type=range]')
          .next()
          .css({ 'z-index': '7' })
        $(target).siblings('input[type=range]').css({ 'z-index': '6' })
      })
  }

  trigger = () => {
    $(this.document)
      .find('input[type=range]')
      .each((_, element) => {
        $(element).trigger('change').trigger('input')
      })
  }
}
