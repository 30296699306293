import { APP } from '..'
import Checkbox from './formControls/Checkbox'
import DateRange from './formControls/DateRange'
import Range from './formControls/Range'

export default class Filter {
  /*
      Hosts POI filtering capabilities for a single filter
  */

  constructor ({ data, parent } = {}) {
    this.promises = []
    this.data = data.data
    this.name = data.name
    this.title = data.title
    this.type = data.type
    this.applies_to = data.applies_to
    this.parent = parent

    // Fill up range values for cotations
    if (this.type === 'range' && data.data == null) {
      this.data = {
        min: 0,
        max: APP.attributes[this.name].data.length - 1,
        step: 1,
        unit: ''
      }
    }

    this.setDefaultValue()
    this.formControl = this.createFormControl()
  }

  createFormControl = () => {
    let control
    switch (this.type) {
      case 'bool':
        control = new Checkbox(this)
        break
      case 'min':
        control = new Range(this, 'slider')
        break
      case 'max':
        control = new Range(this, 'slider')
        break
      case 'range':
        control = new Range(this, 'range')
        break
      case 'dateRange':
        control = new DateRange(this)
        break
      default:
        throw new Error('Invalid filter type.')
    }

    this.promises = this.promises.concat(control.promises)
    return control
  }

  // Outputs whether a given poi passes the filter
  filterPOI = (poi) => {
    // Find which of the POI's attributes the filter applies to
    const filteredArray = this.applies_to.filter((value) =>
      Object.keys(poi).includes(value)
    )

    let score = 0
    filteredArray.forEach((attribute) => {
      const poiValue = poi[attribute]
      const filterValue = this.value

      switch (this.type) {
        case 'bool':
          if (isNaN(poiValue) || +poiValue >= +filterValue) {
            score += 1
          }
          break
        case 'min':
          if (isNaN(poiValue) || poiValue >= filterValue) {
            score += 1
          }
          break
        case 'max':
          if (isNaN(poiValue) || poiValue <= filterValue) {
            score += 1
          }
          break
        case 'range':
          if (
            isNaN(poiValue) ||
                        (poiValue >= filterValue[0] &&
                            poiValue <= filterValue[1])
          ) {
            score += 1
          }
          break
        case 'dateRange':
          if (
            poiValue >= filterValue[0] &&
                        poiValue <= filterValue[1]
          ) {
            score += 1
          }
          break
        default:
          throw new Error('Invalid filter type.')
      }
    })

    if (filteredArray.length === score) {
      return 1
    }
    return 0
  }

  // Set default values depending on filter type
  setDefaultValue = () => {
    switch (this.type) {
      case 'bool':
        this.value = false
        break
      case 'min':
        this.value = -Infinity
        break
      case 'max':
        this.value = -Infinity
        break
      case 'dateRange':
        this.value = [
          new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
          new Date()
        ]
        break
      case 'range':
        this.value = [-Infinity, Infinity]
        break
      default:
        throw new Error(`Invalid filter type '${this.type}' (${this.name}).`)
    }
  }

  parse = (value) => {
    switch (this.type) {
      case 'bool':
        if (value == null) {
          return null
        }
        switch (typeof value) {
          case 'string':
            return value === 'true' || value === '1'
          case 'number':
            return parseInt(value) === 1
          case 'boolean':
            return value
          default:
            return null
        }
      case 'min':
        value = parseInt(value)
        if (isNaN(value)) {
          return null
        }
        return value
      case 'max':
        value = parseInt(value)
        if (isNaN(value)) {
          return null
        }
        return value
      case 'dateRange':
        if (value.length === 2) {
          value = [
            new Date(Date.parse(value[0])),
            new Date(Date.parse(value[1]))
          ]
          if (
            value[0] instanceof Date &&
                        !isNaN(value[0]) &&
                        value[1] instanceof Date &&
                        !isNaN(value[1]) &&
                        value[1] >= value[0]
          ) {
            return value
          }
          return null
        }
        return null
      case 'range':
        if (value.length === 2) {
          value = [parseInt(value[0]), parseInt(value[1])]
          if (!isNaN(value[0]) && !isNaN(value[1])) {
            return value
          }
          return null
        }
        return null
      default:
        throw new Error(`Invalid filter type (${value}).`)
    }
  }
}
