import $ from 'jquery'
import DOMelement from '../DOMelement'
import svgFiles from '../svgFiles'

export default class FormControl extends DOMelement {
  /*
    Hosts HTML logic for a single filter
  */

  constructor (name, data) {
    super(`filters/${name}.html`)
    this.filter = data

    Promise.all(this.promises).then(() => {
      // Input attributes
      $(this.document).attr({ 'data-name': data.name })
      $(this.document)
        .find('[data-group]')
        .attr({ 'data-group': data.family })
      $(this.document).find('[for]').attr({ for: data.name })
      $(this.document).find('[name]').attr({
        name: data.name,
        id: data.name
      })

      // Annotation
      if (data?.data?.annotation) {
        $(this.document)
          .find('span.annotation')
          .html(data.data.annotation)
      } else {
        $(this.document).find('span.annotation').remove()
      }

      // Images
      const svg = svgFiles[data.name]
      $(this.document)
        .find('img')
        .attr({ src: 'data:image/svg+xml;base64,' + btoa(svg) })

      // Unit
      let unit = ''
      if (data?.data?.unit) {
        unit = ` (${data.data.unit.replace(' ', '')})`
      }

      // Title
      $(this.document)
        .find('span.title')
        .html(data.title + unit)

      $(this.document)
        .find('[type="range"]')
        .each((index, element) => {
          $(element).attr({
            min: data.data.min,
            max: data.data.max,
            step: data.data.step
          })
        })

      // data-min and data-max for range elements
      if ($(this.document).is('[data-min]')) {
        $(this.document).attr({ 'data-min': data.data.min })
        $(this.document).attr({ 'data-max': data.data.max })
      }

      // 'Name' and 'for' for range elements
      $(this.document)
        .find('[data-minmax]')
        .each((index, element) => {
          const label = $(element).next('label').first()

          if (label.length > 0) {
            const minmax = $(element).attr('data-minmax')
            const forAttr = $(label).attr('for')
            $(element).attr({
              name: `${forAttr}_${minmax}`,
              id: `${forAttr}_${minmax}`
            })
            $(label).attr({ for: `${forAttr}_${minmax}` })
          }
        })
    })
  }

  updateFilterValue = () => {
    this.filter.value = this.getValue()
    this.filter.parent.addedToMapTrigger()
  }
}
